import React from "react"
import styled from "@emotion/styled"
import RightContainer from "../../Partials/RightContainer"
import SideText from "./SideText"
import { Grid } from "./Grid"
import { fontSize } from "../../../styles/fonts/fontStyle"
import { deviceMinW } from "../../../styles/mediaQueries"

const RowList = ({ rowListItems = [], sideText }) => {
    return (
        <>
            <TextOneColumnOuterContainer>
                <TwoColumnsRightContainer>
                    <Grid>
                        <SideText copy={sideText} />
                        {rowListItems ? (
                            <ListContainer>
                                {rowListItems.map(({ id, text }) => (
                                    <>
                                        <Li key={id}>{text}</Li>
                                    </>
                                ))}
                            </ListContainer>
                        ) : null}
                    </Grid>
                </TwoColumnsRightContainer>
            </TextOneColumnOuterContainer>
        </>
    )
}
export default RowList

const TextOneColumnOuterContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
`
const ListContainer = styled.ul`
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 4rem;
    max-width: 800px;
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: grid;
`

const Li = styled.li`
    font-size: ${fontSize.pica};
    font-weight: 500;

    @media ${deviceMinW.laptop} {
        font-size: ${fontSize.greatPrimer};
    }
`

const TwoColumnsRightContainer = styled(RightContainer)`
    justify-content: space-between;
`
