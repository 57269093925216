import React from "react"
import Introduction from "../../components/caseStudies/Components/Introduction"
import { css } from "@emotion/core"
import { get } from "lodash"
import { graphql } from "gatsby"
import AppContainer from "../../styles/AppContainer"
import Sidebar from "../../components/Sidebar/Sidebar"
import { Helmet } from "react-helmet"
import SEO from "../../components/seo"
import useToggleFooterColor from "../../hooks/useToggleFooterColor"
import RightColumnContent from "../../components/caseStudies/Components/RightColumnContent"
import RowList from "../../components/caseStudies/Components/RowList"
import OrderedColumnList from "../../components/caseStudies/Components/OrderedColumnList"
import IconList from "../../components/caseStudies/Components/IconList"
import ContentBlock from "../../components/caseStudies/Components/ContentBlock"
import Reflections from "../../components/caseStudies/Components/Reflections"
import { colors } from "../../styles/colors"
import { deviceMinW } from "../../styles/mediaQueries"

const Curacao = ({ data }) => {
    const {
        heading,
        logo: clientLogo,
        video: clientVideo,
        introductionCopy: introCopy,
        meta
    } = get(data, "allCuracaoJson.edges[0].node", {})

    const { copy: headingCopy } = heading
    const { image: metaImage } = meta

    const contentBlocks = get(
        data,
        "allCuracaoJson.edges[0].node.contentBlocks"
    )
    console.log("contentBlocks: ", contentBlocks)

    const { paragraphs, images, quotes } = get(
        data,
        "allCuracaoJson.edges[0].node.contentBlocks.items",
        {}
    )

    console.log("paragraphs: ", paragraphs)
    console.log("images: ", images)

    useToggleFooterColor({ useTan: true })

    return (
        <>
            <SEO
                title="Curaçao Case Study | Descriptive"
                description="The story about how we designed Curaçao's global website."
                image={metaImage}
            />
            <Helmet bodyAttributes={{ class: "curacao-page" }} />
            <AppContainer>
                <Sidebar copy="Case Study" fontColor="#c41972" />
            </AppContainer>
            <Introduction
                heading={headingCopy}
                logo={clientLogo}
                video={clientVideo}
                introductionCopy={introCopy}
                fontColor={colors.pampas}
            />
            {contentBlocks.length &&
                contentBlocks.map((contentBlock, i) => {
                    return (
                        <>
                            <section
                                key={i}
                                css={css`
                                    background: ${contentBlock.bgColor};
                                    padding: 5rem 0;

                                    @media ${deviceMinW.tablet} {
                                        padding: 10rem 0;
                                    }
                                `}
                            >
                                <AppContainer>
                                    {contentBlock.type === "Content Block" && (
                                        <>
                                            <ContentBlock
                                                paragraphs={
                                                    contentBlock.paragraphs
                                                }
                                                title={contentBlock.title}
                                            />
                                        </>
                                    )}
                                    {contentBlock.type === "Right Column" && (
                                        <>
                                            <RightColumnContent
                                                items={contentBlock.items}
                                                sideText={contentBlock.sideText}
                                                paragraphs={paragraphs}
                                                images={images}
                                                quotes={quotes}
                                            />
                                        </>
                                    )}
                                    {contentBlock.type === "Row List" && (
                                        <>
                                            <RowList
                                                rowListItems={
                                                    contentBlock.rowListItems
                                                }
                                                sideText={contentBlock.sideText}
                                            />
                                        </>
                                    )}
                                    {contentBlock.type ===
                                        "Ordered Column List" && (
                                        <>
                                            <OrderedColumnList
                                                listItems={
                                                    contentBlock.listItems
                                                }
                                                sideText={contentBlock.sideText}
                                            />
                                        </>
                                    )}
                                    {contentBlock.type === "Icon List" && (
                                        <>
                                            <IconList
                                                iconListItems={
                                                    contentBlock.iconListItems
                                                }
                                                sideText={contentBlock.sideText}
                                            />
                                        </>
                                    )}
                                    {contentBlock.type === "Key Decisions" && (
                                        <>
                                            <RightColumnContent
                                                items={contentBlock.items}
                                                isKeyDecisions
                                            />
                                        </>
                                    )}
                                    {contentBlock.type === "Reflections" && (
                                        <>
                                            <Reflections
                                                paragraphs={
                                                    contentBlock.paragraphs
                                                }
                                                title={contentBlock.title}
                                                fontColor={colors.pampas}
                                            />
                                        </>
                                    )}
                                </AppContainer>
                            </section>
                        </>
                    )
                })}
        </>
    )
}

export default Curacao

export const query = graphql`
    query {
        allCuracaoJson {
            edges {
                node {
                    meta {
                        image {
                            src
                            alt
                        }
                    }
                    logo {
                        src {
                            publicURL
                        }
                        alt
                    }
                    heading {
                        copy {
                            title
                            agencyStatement
                        }
                    }
                    video {
                        src
                        alt
                        poster
                    }
                    introductionCopy {
                        paragraph
                    }
                    contentBlocks {
                        type
                        bgColor
                        sideText
                        title
                        paragraphs {
                            paragraph
                            bquote
                        }
                        items {
                            title
                            paragraphs {
                                paragraph
                            }
                            images {
                                alt
                                columns
                                src {
                                    childImageSharp {
                                        fluid(maxWidth: 800, quality: 100) {
                                            ...GatsbyImageSharpFluid
                                        }
                                    }
                                }
                            }
                        }
                        rowListItems {
                            id
                            text
                        }
                        listItems {
                            id
                            text
                        }
                    }
                }
            }
        }
    }
`
